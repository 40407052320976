<template>
	<v-card>
		<v-card-text class="table">
			<div class="row">
				<div class="column">Username</div>
				<div class="column">
					{{ info.name }}
				</div>
			</div>
			<div class="row">
				<div class="column">Cowoncy</div>
				<div class="column">
					{{ info.cowoncy }}
				</div>
			</div>
			<div class="row">
				<div class="column">Wrapped Common Ticket</div>
				<div class="column">
					{{ parseInt(info.common_tickets) }}
				</div>
			</div>
			<div class="row">
				<div class="column">Unwrapped Common Ticket</div>
				<div class="column">
					{{ parseInt(info.unwrapped_common_tickets) }}
				</div>
			</div>
		</v-card-text>
		<v-card-actions class="buttons">
			<v-spacer></v-spacer>
			<v-btn color="red" @click="ban"> Ban </v-btn>
			<v-btn color="warning" @click="reset"> Reset </v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'AdminTransactionInfo',
	props: {
		info: {
			type: Object,
			required: true,
		},
	},
	data: () => ({}),
	computed: {},
	methods: {
		async ban() {
			const opt = {
				text: `Are you sure you want to ban this user?`,
				width: 600,
				bottomText: `Username: <b>${this.info.name}</b><br>ID: <b>${this.info.id}</b>`,
				buttons: [
					{
						text: 'Ban',
						color: 'error',
						returns: true,
					},
					{
						text: 'Cancel',
						color: 'white',
						outlined: true,
						returns: false,
					},
				],
			};
			const result = await this.$modal(opt).showWarn();
			if (result) {
				try {
					await this.$store.dispatch('banUser', { userId: this.info.id });
				} catch (err) {
					console.error(err);
					await this.$modal({
						text: 'Failed to ban this user',
						bottomText: err,
					}).showError();
				}
			}
		},
		reset() {},
	},
};
</script>

<style scoped>
.row {
	display: flex;
}

.column {
	width: 200px;
}

.table {
	display: flex;
	flex-direction: column;
}

.buttons {
	position: absolute;
	right: 0;
	bottom: 0;
}
</style>
