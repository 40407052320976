<template>
	<div>
		<v-text-field
			v-model="search"
			clearable
			label="Capture ID / Discord ID"
			outlined
			@blur="fetchTransactions"
		>
		</v-text-field>
		<AdminTransactionInfo :info="info" />
		<div class="cards">
			<AdminTransactionCard
				v-for="transaction in transactions"
				:key="transaction._id"
				:transaction="transaction"
			/>
		</div>
	</div>
</template>

<script>
import AdminTransactionCard from '@/views/admin/components/AdminTransactionCard';
import AdminTransactionInfo from '@/views/admin/components/AdminTransactionInfo';

export default {
	name: 'AdminTransactionPage',
	components: { AdminTransactionCard, AdminTransactionInfo },
	data: () => ({
		transactions: [],
		info: {},
	}),
	methods: {
		async fetchTransactions(ele) {
			let id = ele.target.value;
			if (!id) return;
			id = id.trim();
			let result;
			if (/^\d{16,}$/.test(id)) {
				result = await this.$store.dispatch('getTransactionFromDiscordId', id);
			} else {
				result = await this.$store.dispatch('getTransactionFromCaptureId', id);
			}
			console.log(result);
			this.transactions = result.transaction;
			this.info = result.info;
		},
	},
	async mounted() {
		try {
			const result = await this.$store.dispatch('getTransactions');
			this.transactions = result.transaction;
			this.info = result.info;
		} catch (err) {
			console.error(err);
		}
	},
};
</script>

<style scoped>
.cards {
	display: flex;
	flex-wrap: wrap;
}
</style>
