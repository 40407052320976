<template>
	<v-hover v-slot="{ hover }">
		<v-card width="400" class="ma-1 transaction-card">
			<v-card-title>
				<v-avatar size="40">
					<img alt="user" :src="avatarUrl" @error="avatarError" />
				</v-avatar>
				<v-spacer></v-spacer>
				<div class="mt-1 mb-0 username">
					<p class="mb-0">{{ username }}</p>
					<p class="mb-0 discord-id">{{ discordId }}</p>
				</div>
			</v-card-title>
			<v-card-text class="pb-7">
				<div v-for="row in list" :key="row.title" class="row px-3">
					<span>{{ row.title }}</span>
					<div class="data">
						<span v-for="data in row.datas" :key="data">{{
							data || 'N/A'
						}}</span>
					</div>
				</div>
			</v-card-text>
			<v-expand-transition>
				<div
					v-if="hover"
					class="overlay text-h2 noselect"
					v-ripple
					@click="ban"
				>
					Ban?
				</div>
			</v-expand-transition>
		</v-card>
	</v-hover>
</template>

<script>
export default {
	name: 'AdminTransactionCard',
	props: {
		transaction: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		avatarGifError: false,
		avatarPngError: false,
	}),
	computed: {
		username() {
			return `${this.transaction.user.username}#${this.transaction.user.discriminator}`;
		},
		discordId() {
			return `${this.transaction.user.discordId}`;
		},
		avatarUrl() {
			const user = this.transaction.user;
			if (!user.avatar) {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(user.discriminator) % 5
				}.png`;
			} else if (!this.avatarGifError) {
				return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.gif`;
			} else if (!this.avatarPngError) {
				return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.png`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(user.discriminator) % 5
				}.png`;
			}
		},
		list() {
			const list = [
				{
					title: 'Delivered On',
					datas: [this.toDate(this.transaction.createdAt)],
				},
				{
					title: 'Capture ID',
					datas: [this.transaction.captureID],
				},
				{
					title: 'Total Price',
					datas: ['$' + this.transaction.total],
				},
				{
					title: 'Items',
					datas: [],
				},
				{
					title: 'Payer',
					datas: [
						this.transaction.payer_name,
						this.transaction.email_address,
						this.transaction.payer_id,
					],
				},
				{
					title: 'Gift To',
					datas: [this.transaction.giftUserId],
				},
				{
					title: 'IP',
					datas: [this.transaction.ip],
				},
				{
					title: 'Site Banned',
					datas: [this.transaction.user.banned],
				},
			];
			this.transaction.items.forEach((item) => {
				list[3].datas.push(`${item.itemID} x${item.count}`);
			});
			return list;
		},
	},
	methods: {
		avatarError() {
			if (!this.avatarGifError) {
				this.avatarGifError = true;
			} else if (!this.avatarPngError) {
				this.avatarPngError = true;
			}
		},
		toDate(date) {
			return new Date(date).toLocaleString();
		},
		async ban() {
			const email = this.transaction.email_address;
			const opt = {
				text: `Are you sure you want to ban this user?`,
				width: 600,
				bottomText: `Username: <b>${this.transaction.user.username}</b><br>ID: <b>${this.transaction.user.discordId}</b><br>Email: <b>${this.transaction.email_address}</b>`,
				buttons: [
					{
						text: 'Ban',
						color: 'error',
						returns: true,
					},
					{
						text: 'Cancel',
						color: 'white',
						outlined: true,
						returns: false,
					},
				],
			};
			const result = await this.$modal(opt).showWarn();
			if (result) {
				try {
					await this.$store.dispatch('banUser', {
						email,
						userId: this.transaction.user.discordId,
						giftId: this.transaction.giftUserId,
						ip: this.transaction.ip,
					});
				} catch (err) {
					console.error(err);
					await this.$modal({
						text: 'Failed to ban this email',
						bottomText: err,
					}).showError();
				}
			}
		},
	},
};
</script>

<style scoped>
.row {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	height: min-content;
}

.row span {
	width: min-content;
	flex: 1;
	color: white;
}

.row div {
	width: 100%;
	flex: 1;
}

.data {
	display: flex;
	flex-direction: column;
}

.data span {
	width: 100%;
	text-align: right;
	color: rgba(255, 255, 255, 0.7);
	white-space: nowrap;
}

.username {
	display: flex;
	flex-direction: column;
	text-align: right;
}

.discord-id {
	color: rgba(255, 255, 255, 0.7);
	font-size: 0.875rem;
	margin-top: -11px;
}

.overlay {
	position: absolute;
	background: var(--v-error-base);
	width: 100%;
	height: 100%;
	bottom: 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}

.transaction-card {
	position: relative;
}
</style>
